<template>
  <b-modal
    :id="`modal-update-employee-service-${leistung.id}`"
    centered
    title="Mitarbeiterleistung bearbeiten"
    ok-title="Aktualisieren"
    cancel-title="Abbrechen"
    cancel-variant="outline-secondary"
    @ok="updateTagesleistung"
  >
    <!-- Form -->
    <validation-observer ref="updatePerformanceForm" #default="{ invalid }">
      <b-form class="auth-register-form mt-2" @submit.prevent="register">
        <b-card-title class="mb-1">Mitarbeiterleistung bearbeiten</b-card-title>
        <b-row class="align-items-center justify-content-center">
          <b-col cols="6">
            <b-form-group
              label="Arbeitsbeginn"
              label-for="mitarbeiterleistung-bearbeiten-arbeitsbeginn"
            >
              <validation-provider
                #default="{ errors }"
                name="Arbeitsbeginn"
                vid="arbeitsbeginn"
                rules="required"
              >
                <flat-pickr
                  v-model="leistung.Arbeitsbeginn"
                  id="mitarbeiterleistung-bearbeiten-arbeitsbeginn"
                  class="form-control"
                  :config="flatPickrConfig"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Arbeitsende"
              label-for="mitarbeiterleistung-bearbeiten-arbeitsende"
            >
              <validation-provider
                #default="{ errors }"
                name="Arbeitsende"
                vid="arbeitsende"
                rules="required"
              >
                <flat-pickr
                  v-model="leistung.Arbeitsende"
                  id="mitarbeiterleistung-bearbeiten-arbeitsende"
                  class="form-control"
                  :config="flatPickrConfig"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Pause"
              label-for="mitarbeiterleistung-bearbeiten-startzeit"
            >
              <validation-provider
                #default="{ errors }"
                name="Pause"
                vid="pause"
                rules="required"
              >
                <b-input-group append="Minuten">
                  <b-form-input
                    v-model.number="pause"
                    id="mitarbeiterleistung-bearbeiten-pause"
                    class="form-control"
                    type="number"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" class="py-1">
            <label>Nachtschicht</label>
            <b-form-checkbox
              id="mitarbeiterleistung-bearbeiten-nachtschicht"
              class="d-inline-block ml-50 mr-2"
              v-model="nachtschicht"
            />
            <label> Feiertag</label>
            <b-form-checkbox
              id="mitarbeiterleistung-bearbeiten-feiertag"
              class="d-inline-block ml-50"
              v-model="leistung.Feiertag"
            />
          </b-col>
          <b-col cols="12" class="py-1">
            <label class="w-100">Geprüfte Teile</label>
            <div
              class="d-flex flex-wrap justify-content-between align-items-center"
            >
              <b-card bg-variant="success" class="mb-1 details-card">
                <b-form-group
                  label-class="text-white"
                  label="I.O."
                  label-for="mitarbeiterleistung-bearbeiten-io"
                >
                  <b-form-input
                    v-model.number="leistung.Teile_IO"
                    id="mitarbeiterleistung-bearbeiten-io"
                    type="number"
                  />
                </b-form-group>
              </b-card>
              <b-card bg-variant="danger" class="mb-1 details-card">
                <b-form-group
                  label-class="text-white"
                  label="N.I.O."
                  label-for="mitarbeiterleistung-bearbeiten-nio"
                >
                  <b-form-input
                    v-model.number="leistung.Teile_NIO"
                    id="mitarbeiterleistung-bearbeiten-nio"
                    type="number"
                  />
                </b-form-group>
              </b-card>
              <b-card bg-variant="success" class="mb-1 details-card">
                <b-form-group
                  label-class="text-white"
                  label="Nacharbeitet I.O."
                  label-for="mitarbeiterleistung-bearbeiten-nachgearbeitet-io"
                >
                  <b-form-input
                    v-model.number="leistung.Teile_Nachgearbeitet_IO"
                    id="mitarbeiterleistung-bearbeiten-nachgearbeitet-io"
                    type="number"
                  />
                </b-form-group>
              </b-card>
              <b-card bg-variant="danger" class="mb-1 details-card">
                <b-form-group
                  label-class="text-white"
                  label="Nacharbeitet N.I.O."
                  label-for="mitarbeiterleistung-bearbeiten-nachgearbeitet-nio"
                >
                  <b-form-input
                    v-model.number="leistung.Teile_Nachgearbeitet_NIO"
                    id="mitarbeiterleistung-bearbeiten-nachgearbeitet-nio"
                    type="number"
                  />
                </b-form-group>
              </b-card>
              <b-card bg-variant="primary" class="w-100 mb-0 details-card">
                <b-form-group
                  label-class="text-white"
                  label="Geprüft gesamt"
                  label-for="mitarbeiterleistung-bearbeiten-teile-gesamt"
                >
                  <b-form-input
                    v-model="leistung.Teile_Gesamt"
                    id="mitarbeiterleistung-bearbeiten-teile-gesamt"
                    type="number"
                    disabled
                  />
                </b-form-group>
              </b-card>
            </div>
          </b-col>
          <b-col cols="12" class="py-1">
            <label> <h3>Tätigkeit ausgeführt</h3></label>
            <b-form-checkbox
              id="mitarbeiterleistung-bearbeiten-taetigkeit-ausgefuehrt"
              class="d-inline-block ml-1"
              v-model="leistung.Taetigkeit_Ausgefuehrt"
            />
          </b-col>
          <b-col cols="12" class="py-1">
            <b-form-group
              label="Abweichendes Fehlerbild"
              label-for="mitarbeiterleistung-bearbeiten-abweichendes-fehlerbild"
            >
              <b-form-textarea
                v-model="leistung.Abweichendes_Fehlerbild"
                id="mitarbeiterleistung-bearbeiten-abweichendes-fehlerbild"
                placeholder="Bei abweichendem Fehlerbild, bitte kurz beschreiben"
                rows="3"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" class="py-1">
            <b-form-group
              label="Tätigkeitsmerkmale (Prüftätigkeit)"
              label-for="mitarbeiterleistung-bearbeiten-taetigkeitsmerkmale"
            >
              <b-form-textarea
                v-model="leistung.Taetigkeitsmerkmale"
                id="mitarbeiterleistung-bearbeiten-taetigkeitsmerkmale"
                placeholder="Kurze Beschreibung der Tätigkeit"
                rows="3"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" class="py-1">
            <b-form-group
              label="Besonderheiten/Sonstiges"
              label-for="mitarbeiterleistung-bearbeiten-sonstiges"
            >
              <b-form-textarea
                v-model="leistung.Sonstiges"
                id="mitarbeiterleistung-bearbeiten-sonstiges"
                placeholder="Kurze Beschreibung von Besonderheiten (z.B. Stillstandzeiten, abweichende Prüfkriterien, etc.)"
                rows="3"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BCard,
  BModal,
  BRow,
  BCol,
  BCardTitle,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BFormCheckbox,
  BFormTextarea,
} from 'bootstrap-vue';
import { required } from '@validations';
import flatPickr from 'vue-flatpickr-component';
import { German } from 'flatpickr/dist/l10n/de';
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate';
import de from 'vee-validate/dist/locale/de.json';
import Ripple from 'vue-ripple-directive';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

localize('de', de);

export default {
  components: {
    BCard,
    BModal,
    BRow,
    BCol,
    BCardTitle,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BFormCheckbox,
    BFormTextarea,
    ValidationObserver,
    ValidationProvider,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  props: {
    pruefpositionID: {
      required: true,
    },
    leistung: {
      required: true,
    },
  },
  data() {
    return {
      controlPositionData: {},
      nachtschicht: false,
      pause: 0,
      flatPickrConfig: {
        enableTime: true,
        dateFormat: 'Z',
        altInput: true,
        altFormat: 'd.m.Y, H:i \\U\\h\\r',
        allowInput: true,
        time_24hr: true,
        defaultHour: 7,
        locale: German,
      },
    };
  },
  computed: {
    partsSum() {
      return (
        +this.leistung.Teile_IO +
        +this.leistung.Teile_NIO +
        +this.leistung.Teile_Nachgearbeitet_IO +
        +this.leistung.Teile_Nachgearbeitet_NIO
      );
    },
  },
  watch: {
    partsSum() {
      // Set total checked Parts
      this.leistung.Teile_Gesamt = this.partsSum;
    },
    pause() {
      // Format Break to Minutes
      const breakMinutes = new Date(0);
      breakMinutes.setMinutes(+this.pause);
      this.leistung.Pause = breakMinutes.toISOString().substr(11, 8);
    },
  },
  created() {
    this.nachtschicht = this.leistung.Arbeitszeit_Nachtschicht ? true : false;
    this.pause =
      +this.leistung.Pause.split(':')[0] * 60 +
      +this.leistung.Pause.split(':')[1];
  },
  methods: {
    async updateTagesleistung(pruefpositionIndex) {
      this.$refs.updatePerformanceForm.validate().then(async isValid => {
        if (!isValid || this.orderIdNotUnique) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Eingabe nicht korrekt!`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: `Die Eingabe ist nicht korrekt. Bitte überprüfen Sie alle Felder und probieren Sie es noch einmal.`,
            },
          });
          return;
        }

        // Format Break to Minutes
        const breakMinutes = new Date(0);
        breakMinutes.setMinutes(+this.pause);

        // Calculate working time
        const arbeitsbeginn = new Date(this.leistung.Arbeitsbeginn);
        const arbeitsende = new Date(this.leistung.Arbeitsende);
        const arbeitszeit = new Date(
          arbeitsende - arbeitsbeginn - breakMinutes
        );
        this.leistung.Arbeitszeit = arbeitszeit.toISOString().substr(11, 8);

        if (this.nachtschicht) {
          this.leistung.Arbeitszeit_Nachtschicht = this.leistung.Arbeitszeit;
        }
        if (!this.nachtschicht) {
          this.leistung.Arbeitszeit_Nachtschicht = null;
        }
        if (arbeitsbeginn.getDay() === 6) {
          this.leistung.Arbeitszeit_Samstag = this.leistung.Arbeitszeit;
        }
        if (arbeitsbeginn.getDay() === 0 || this.leistung.Feiertag) {
          this.leistung.Arbeitszeit_Wochenende = this.leistung.Arbeitszeit;
        }

        const qs = require('qs');
        const query = qs.stringify(
          {
            fields: ['Status', 'Stueckzahl'],
            populate: {
              Mitarbeiterleistungen: {
                populate: '*',
              },
            },
          },
          {
            encodeValuesOnly: true,
            indices: false,
          }
        );
        await this.$http
          .get(`/pruefpositionen/${this.pruefpositionID}?${query}`)
          .then(async response => {
            const pruefpositionData = response.data.data;
            let checkedParts = 0;
            pruefpositionData.Mitarbeiterleistungen.forEach(
              (mitarbeiterleistung, index) => {
                if (mitarbeiterleistung.id === this.leistung.id) {
                  pruefpositionData.Mitarbeiterleistungen[index] =
                    this.leistung;
                }
                checkedParts +=
                  pruefpositionData.Mitarbeiterleistungen[index].Teile_Gesamt;
              }
            );

            // Change Status if everything is done
            if (checkedParts >= pruefpositionData.Stueckzahl) {
              pruefpositionData.Status = 'Beendet';
            }

            // Update Mitarbeiterleistung
            await this.$http
              .put(`/pruefpositionen/${this.pruefpositionID}`, {
                data: pruefpositionData,
              })
              .then(async () => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: `Tagsesleistung aktualisiert`,
                    icon: 'SaveIcon',
                    variant: 'success',
                    text: `Die Tagesleistung wurde erfolgreich aktualisiert.`,
                  },
                });
                this.$emit('tagesleistungUpdated');
              })
              .catch(err => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: `Aktualisierung der Tagesleistung fehlgeschlagen!`,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    text: `Die Tagesleistung konnte nicht aktualisiert werden. Bitte überprüfen Sie die Eingabe und probieren Sie es noch einmal.`,
                  },
                });
                console.log(err.response.data.error.message);
              });
          })
          .catch(err => console.log(err));
      });
    },
  },
};
</script>
