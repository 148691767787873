<template>
  <section class="tagesbericht-add-wrapper">
    <b-row class="tagesbericht-add">
      <!-- Col: Left (Invoice Container) -->
      <b-col cols="12" xl="10" md="10">
        <validation-observer ref="registerReportForm" #default="{ invalid }">
          <b-form @submit.prevent>
            <b-card no-body class="tagesbericht-preview-card">
              <!-- Header -->
              <b-card-body class="tagesbericht-padding pb-0">
                <b-row
                  class="d-flex justify-content-between flex-md-row flex-column tagesbericht-spacing mt-0 mb-0"
                >
                  <!-- Header: Left Content -->
                  <b-col cols="4">
                    <div class="logo-wrapper">
                      <logo />
                      <h3 class="text-primary tagesbericht-logo">
                        <span>Tempo</span>Ratio
                      </h3>
                    </div>
                    <p class="card-text mb-25">Carl-Benz-Straße 3</p>
                    <p class="card-text mb-25">68723 Schwetzingen</p>
                    <p class="card-text mb-25">Tel.: 06202 / 859349-0</p>
                    <p class="card-text mb-25">Fax: 06202 / 859349-9</p>
                    <p class="card-text mb-25">
                      Email: pruefergebnis@temporatio.de
                    </p>
                    <p class="card-text mb-25">Webseite: www.temporatio.de</p>
                  </b-col>

                  <!-- Header: Right Content -->
                  <b-col cols="4" class="tagesbericht-number-date mt-md-0 mt-2">
                    <div
                      class="d-flex align-items-center justify-content-md-end mb-50"
                      v-if="mitarbeiterData.User.avatar"
                    >
                      <b-avatar
                        :src="
                          mitarbeiterData.User.avatar
                            ? $dbBaseUrl +
                              mitarbeiterData.User.avatar.formats.thumbnail.url
                            : ''
                        "
                        :text="`${mitarbeiterData.Vorname.charAt()}${mitarbeiterData.Nachname.charAt()}`"
                        :variant="`light-primary`"
                        size="104px"
                      />
                    </div>
                    <div
                      class="d-flex align-items-center justify-content-md-between mb-50"
                    >
                      <h5 class="mr-1">Mitarbeiter:</h5>
                      <h5>
                        {{ mitarbeiterData.Vorname }}
                        {{ mitarbeiterData.Nachname }}
                      </h5>
                    </div>
                    <div
                      class="d-flex align-items-center justify-content-md-between mb-50"
                    >
                      <h5 class="">Mitarbeiternummer:</h5>
                      <h5>#{{ mitarbeiterData.Mitarbeiternummer }}</h5>
                    </div>
                    <div
                      class="d-flex align-items-center justify-content-md-between mb-50"
                    >
                      <h5 class="">Anstellungsart:</h5>
                      <h5>{{ mitarbeiterData.Anstellungsart }}</h5>
                    </div>
                    <div
                      class="d-flex align-items-center justify-content-md-between mb-50"
                    >
                      <h5 class="">Stundensatz:</h5>
                      <h5>{{ mitarbeiterData.Stundensatz }} €</h5>
                    </div>
                    <div
                      class="d-flex align-items-center justify-content-md-between mb-50"
                    >
                      <h5 class="mr-1">Berichtnummer:</h5>
                      <validation-provider
                        #default="{ errors }"
                        name="Berichtnummer"
                        vid="berichtnummer"
                        rules="required|min:5"
                      >
                        <b-input-group
                          class="input-group-merge tagesbericht-edit-input-group disabled mw-100"
                        >
                          <b-input-group-prepend is-text>
                            <feather-icon icon="HashIcon" />
                          </b-input-group-prepend>
                          <b-form-input
                            class="pl-1 text-right"
                            id="tagesbericht-data-id"
                            v-model="berichtData.Mitarbeiterberichtnummer"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small v-if="reportIdNotUnique" class="text-danger"
                          >Berichtnummer bereits vergeben</small
                        >
                      </validation-provider>
                    </div>
                  </b-col>
                  <b-col cols="12" class="mt-2">
                    <b-row>
                      <b-col cols="12" lg="3">
                        <b-form-group label="Startdatum" label-for="startdatum">
                          <b-form-datepicker
                            id="startdatum"
                            v-model="berichtData.Startdatum"
                            v-bind="{
                              labelPrevDecade: 'Vorheriges Jahrzehnt',
                              labelPrevYear: 'Vorheriges Jahr',
                              labelPrevMonth: 'Vorheriger Monat',
                              labelCurrentMonth: 'Aktueller Monat',
                              labelNextMonth: 'Nächster Monat',
                              labelNextYear: 'Nächstes Jahr',
                              labelNextDecade: 'Nächstes Jahrzehnt',
                              labelToday: 'Heute',
                              labelSelected: 'Ausgewähltes Datum',
                              labelNoDateSelected: 'Kein Datum gewählt',
                              labelCalendar: 'Kalender',
                              labelNav: 'Kalendernavigation',
                              labelHelp: 'Monat/Jahr mit Pfeiltasten ändern',
                            }"
                            :start-weekday="1"
                            show-decade-nav
                            locale="de"
                            aria-controls="startdatum"
                            placeholder="Datum auswählen"
                            @input="
                              getMitarbeiterData(
                                berichtData.Startdatum,
                                berichtData.Enddatum
                              )
                            "
                          />
                        </b-form-group>
                      </b-col>

                      <b-col cols="12" lg="3">
                        <b-form-group label="Enddatum" label-for="enddatum">
                          <b-form-datepicker
                            id="enddatum"
                            v-model="berichtData.Enddatum"
                            v-bind="{
                              labelPrevDecade: 'Vorheriges Jahrzehnt',
                              labelPrevYear: 'Vorheriges Jahr',
                              labelPrevMonth: 'Vorheriger Monat',
                              labelCurrentMonth: 'Aktueller Monat',
                              labelNextMonth: 'Nächster Monat',
                              labelNextYear: 'Nächstes Jahr',
                              labelNextDecade: 'Nächstes Jahrzehnt',
                              labelToday: 'Heute',
                              labelSelected: 'Ausgewähltes Datum',
                              labelNoDateSelected: 'Kein Datum gewählt',
                              labelCalendar: 'Kalender',
                              labelNav: 'Kalendernavigation',
                              labelHelp: 'Monat/Jahr mit Pfeiltasten ändern',
                            }"
                            :start-weekday="1"
                            show-decade-nav
                            locale="de"
                            aria-controls="enddatum"
                            placeholder="Datum auswählen"
                            @input="
                              getMitarbeiterData(
                                berichtData.Startdatum,
                                berichtData.Enddatum
                              )
                            "
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" lg="3">
                        <b-form-group
                          label="Art des Berichts"
                          label-for="art-des-berichts"
                        >
                          <v-select
                            v-model="berichtData.Mitarbeiterbericht_Art"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :reduce="
                              val => (typeof val === 'string' ? val : val.value)
                            "
                            :options="[
                              {
                                label: 'Alle Aufträge',
                                value: 'Alle_Auftraege',
                              },
                              {
                                label: 'Einzelner Auftrag',
                                value: 'Einzelner_Auftrag',
                              },
                            ]"
                            :clearable="false"
                            class="mb-2 item-selector-title"
                            placeholder="Art des Berichts auswählen"
                            id="art-des-berichts"
                          >
                            <template #selected-option="{ label }">
                              {{ label }}
                            </template>
                            <template #option="{ label }">
                              {{ label }}
                            </template>
                          </v-select>
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        lg="3"
                        v-if="
                          berichtData.Mitarbeiterbericht_Art ===
                            'Einzelner_Auftrag' &&
                          berichtDataGesamt.Auftraege.length > 0
                        "
                      >
                        <b-form-group
                          label="Ausgewählter Auftrag"
                          label-for="ausgewaehlter-auftrag"
                        >
                          <v-select
                            v-model="selectedOrder"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :reduce="val => val.Auftragsnummer"
                            :options="berichtDataGesamt.Auftraege"
                            @open="trSetHeight($refs.form.scrollHeight)"
                            @close="trSetHeight($refs.form.scrollHeight)"
                            @input="trSetHeight($refs.form.scrollHeight)"
                            :clearable="false"
                            class="mb-2 item-selector-title"
                            placeholder="Auftrag auswählen"
                            id="ausgewaehlter-auftrag"
                          >
                            <template #selected-option="{ Auftragsnummer }">
                              #{{ Auftragsnummer }}
                            </template>
                            <template #option="{ Auftragsnummer }">
                              #{{ Auftragsnummer }}
                            </template>
                          </v-select>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-card-body>

              <!-- Spacer -->
              <hr />

              <!-- Items Section -->
              <b-card-body
                class="tagesbericht-padding form-item-section"
                v-if="!noOrders"
              >
                <b-row
                  v-if="berichtData.Mitarbeiterbericht_Art === 'Alle_Auftraege'"
                >
                  <b-col cols="12">
                    <h4>Auswertung aller Leistungen</h4>
                  </b-col>

                  <b-col cols="12">
                    <b-row>
                      <!-- Arbeitszeit gesamt -->
                      <b-col cols="12" lg="3">
                        <b-form-group
                          label="Arbeitszeit gesamt"
                          :label-for="`arbeitszeit-gesamt`"
                        >
                          <b-input-group append="ih" class="mb-2">
                            <b-form-input
                              :value="
                                convertToIndustrialHours(
                                  berichtDataGesamt.gesamt.Arbeitszeit
                                )
                              "
                              :id="`arbeitszeit-gesamt`"
                              :name="`arbeitszeit-gesamt`"
                              disabled
                            />
                          </b-input-group>
                        </b-form-group>
                      </b-col>

                      <!-- Arbeitszeit Nachtschicht gesamt -->
                      <b-col
                        cols="12"
                        lg="3"
                        v-if="berichtDataGesamt.gesamt.Arbeitszeit_Nachtschicht"
                      >
                        <b-form-group
                          label="Arbeitszeit Nachtschicht gesamt"
                          :label-for="`arbeitszeit-nachtschicht-gesamt`"
                        >
                          <b-input-group append="ih" class="mb-2">
                            <b-form-input
                              :value="
                                convertToIndustrialHours(
                                  berichtDataGesamt.gesamt
                                    .Arbeitszeit_Nachtschicht
                                )
                              "
                              :id="`arbeitszeit-nachtschicht-gesamt`"
                              :name="`arbeitszeit-nachtschicht-gesamt`"
                              disabled
                            />
                          </b-input-group>
                        </b-form-group>
                      </b-col>

                      <!-- Arbeitszeit Wochenende gesamt -->
                      <b-col
                        cols="12"
                        lg="3"
                        v-if="berichtDataGesamt.gesamt.Arbeitszeit_Wochenende"
                      >
                        <b-form-group
                          label="Arbeitszeit Wochenende/Feiertag gesamt"
                          :label-for="`arbeitszeit-wochenende-gesamt`"
                        >
                          <b-input-group append="ih" class="mb-2">
                            <b-form-input
                              :value="
                                convertToIndustrialHours(
                                  berichtDataGesamt.gesamt
                                    .Arbeitszeit_Wochenende
                                )
                              "
                              :id="`arbeitszeit-wochenende-gesamt`"
                              :name="`arbeitszeit-wochenende-gesamt`"
                              disabled
                            />
                          </b-input-group>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <div
                  ref="form"
                  class="repeater-form"
                  :style="{ height: trHeight }"
                >
                  <h4>Auswertung der Leistungen nach Auftrag</h4>
                  <b-row
                    v-for="(order, index) in mitarbeiterData.Auftraege"
                    :key="order.id"
                    ref="row"
                    class="pb-2"
                    v-if="
                      berichtData.Mitarbeiterbericht_Art === 'Alle_Auftraege' ||
                      (berichtData.Mitarbeiterbericht_Art ===
                        'Einzelner_Auftrag' &&
                        selectedOrder === order.Auftragsnummer)
                    "
                  >
                    <!-- Item Form -->
                    <!-- ? This will be in loop => So consider below markup for single item -->
                    <b-col cols="12">
                      <!-- Form Input Fields OR content inside bordered area  -->
                      <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                      <div class="d-flex border rounded">
                        <b-row class="flex-grow-1 p-2">
                          <!-- Single Item Form Headers -->
                          <!-- Pruefposition -->
                          <b-col cols="12">
                            <h4>Auftrag #{{ order.Auftragsnummer }}</h4>
                            <p>
                              Arbeitsstunden:
                              {{
                                convertToIndustrialHours(
                                  berichtDataGesamt.Auftraege[index].Arbeitszeit
                                )
                              }}
                              ih
                            </p>
                          </b-col>
                          <div
                            class="d-flex flex-column border rounded mx-1 mb-2 p-2 w-100"
                            v-for="(
                              pruefposition, indexPruefposition
                            ) in order.Pruefpositionen"
                            :key="pruefposition.id"
                            v-if="
                              pruefposition.Mitarbeiterleistungen.length > 0
                            "
                          >
                            <h5>
                              Prüfposition - {{ pruefposition.Bezeichnung }}
                            </h5>
                            <p>
                              Arbeitsstunden:
                              {{
                                convertToIndustrialHours(
                                  berichtDataGesamt.Auftraege[index]
                                    .Pruefpositionen[indexPruefposition]
                                    .Arbeitszeit
                                )
                              }}
                              ih
                            </p>
                            <b-table
                              :items="pruefposition.Mitarbeiterleistungen"
                              :fields="mitarbeiterleistungenFields"
                              :per-page="100"
                              :current-page="1"
                              sort-by="Datum"
                              striped
                              responsive
                              class="mb-0"
                              :id="`mitarbeiterleistungen-${index}`"
                            >
                              <!-- Column: Datum -->
                              <template #cell(Datum)="data">
                                {{
                                  new Date(
                                    data.item.Arbeitsbeginn
                                  ).toLocaleDateString('de-DE', {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: 'numeric',
                                  })
                                }}
                              </template>

                              <!-- Column: BeginnEnde -->
                              <template #cell(BeginnEnde)="data">
                                {{
                                  new Date(
                                    data.item.Arbeitsbeginn
                                  ).toLocaleTimeString('de-DE', {
                                    hour: '2-digit',
                                    minute: '2-digit',
                                  })
                                }}
                                -
                                {{
                                  new Date(
                                    data.item.Arbeitsende
                                  ).toLocaleTimeString('de-DE', {
                                    hour: '2-digit',
                                    minute: '2-digit',
                                  })
                                }}
                                Uhr |
                                {{ convertToMinutes(data.item.Pause) }} Min.
                                Pause
                              </template>

                              <!-- Column: Arbeitszeit -->
                              <template #cell(Arbeitszeit)="data">
                                {{
                                  convertToIndustrialHours(
                                    data.item.Arbeitszeit
                                  )
                                }}
                                ih
                              </template>

                              <!-- Column: Arbeitszeit Nachtschicht -->
                              <template #cell(Arbeitszeit_Nachtschicht)="data">
                                {{
                                  data.item.Arbeitszeit_Nachtschicht
                                    ? `${convertToIndustrialHours(
                                        data.item.Arbeitszeit_Nachtschicht
                                      )} ih`
                                    : `-`
                                }}
                              </template>

                              <!-- Column: Arbeitszeit Wochenende -->
                              <template #cell(Arbeitszeit_Wochenende)="data">
                                {{
                                  data.item.Arbeitszeit_Wochenende
                                    ? `${convertToIndustrialHours(
                                        data.item.Arbeitszeit_Wochenende
                                      )} ih`
                                    : `-`
                                }}
                              </template>
                            </b-table>
                          </div>
                        </b-row>
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </b-card-body>

              <b-card-body v-if="noOrders">
                <b-row class="ml-1" ref="noData">
                  <b-col cols="12">
                    <h4>Es gibt keine Daten für diesen Zeitraum</h4>
                  </b-col>
                </b-row>
              </b-card-body>

              <!-- Spacer -->
              <hr class="tagesbericht-spacing" />

              <!-- Note -->
              <b-card-body class="tagesbericht-padding pt-0">
                <span class="font-weight-bold">Anmerkung: </span>
                <b-form-textarea
                  v-model="berichtData.Anmerkung"
                  placeholder="Zusätzliche Anmerkung zum Bericht"
                />
              </b-card-body>
            </b-card>
          </b-form>
        </validation-observer>
      </b-col>

      <!-- Right Col: Card -->
      <b-col cols="12" md="2" xl="2" class="tagesbericht-actions mt-md-0 mt-2">
        <!-- Action Buttons -->
        <b-card>
          <!-- Button: Save -->
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            block
            :disabled="noOrders"
            @click="saveBericht()"
          >
            Bericht Speichern
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import Logo from '@/layouts/components/TempoRatioLogo.vue';
import { heightTransition } from '@core/mixins/ui/transition';
import Ripple from 'vue-ripple-directive';
import {
  BAvatar,
  BRow,
  BBadge,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BForm,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BFormCheckbox,
  BPopover,
  BTable,
  BTooltip,
  VBToggle,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import VueApexCharts from 'vue-apexcharts';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import UpdateEmployeeServiceModal from '@/layouts/components/modals/UpdateEmployeeServiceModal.vue';
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate';
import de from 'vee-validate/dist/locale/de.json';

localize('de', de);

export default {
  components: {
    BAvatar,
    BRow,
    BBadge,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BForm,
    BFormDatepicker,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    BTable,
    BTooltip,
    vSelect,
    Logo,
    VueApexCharts,
    ValidationProvider,
    ValidationObserver,
    UpdateEmployeeServiceModal,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  mixins: [heightTransition],
  data() {
    return {
      reportIdNotUnique: false,
      mitarbeiterID: this.$route.params.id,
      selectedOrder: null,
      mitarbeiterData: {
        id: null,
        Vorname: null,
        Nachname: null,
        Mitarbeiternummer: null,
        Anstellungsart: null,
        Stundensatz: 14,
        Auftraege: [],
        User: {
          avatar: null,
        },
      },
      pruefpositionen: [],
      berichtData: {
        Mitarbeiterbericht_Art: 'Alle_Auftraege',
        Mitarbeiterberichtnummer: null,
        Mitarbeiterbericht_Datum: new Date().toISOString(),
        Startdatum: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          1
        ),
        Enddatum: new Date(),
        Pruefpositionen: [],
      },
      mitarbeiterleistungenFields: [
        {
          key: 'Datum',
          label: 'Datum',
          sortable: true,
        },
        {
          key: 'BeginnEnde',
          label: 'Arbeitsbeginn/Arbeitsende',
          sortable: true,
        },
        {
          key: 'Arbeitszeit',
          label: 'Arbeitszeit',
          sortable: true,
        },
        {
          key: 'Arbeitszeit_Nachtschicht',
          label: 'Arbeitszeit Nachtschicht',
          sortable: true,
        },
        {
          key: 'Arbeitszeit_Wochenende',
          label: 'Arbeitszeit Wochenende/Feiertag',
          sortable: true,
        },
      ],
    };
  },
  computed: {
    noOrders() {
      if (this.mitarbeiterData.Auftraege.length === 0) {
        return true;
      } else {
        return false;
      }
    },
    berichtDataGesamt() {
      const createInitialData = () => ({
        Arbeitszeit: null,
        Arbeitszeit_Nachtschicht: null,
        Arbeitszeit_Wochenende: null,
      });

      const arbeitszeitData = {
        gesamt: createInitialData(),
        Auftraege: this.mitarbeiterData.Auftraege.map(order => ({
          Auftragsnummer: order.Auftragsnummer,
          ...createInitialData(),
          Pruefpositionen: order.Pruefpositionen.flatMap(
            (pruefposition, pruefpositionIndex) =>
              pruefposition.Mitarbeiterleistungen.length
                ? [
                    {
                      Bezeichnung: pruefposition.Bezeichnung,
                      ...createInitialData(),
                      Mitarbeiterleistungen:
                        pruefposition.Mitarbeiterleistungen.map(
                          mitarbeiterleistung => ({
                            Datum: new Date(mitarbeiterleistung.Arbeitsbeginn),
                            beginnEnde: `${new Date(
                              mitarbeiterleistung.Arbeitsbeginn
                            ).toLocaleTimeString('de-DE', {
                              hour: '2-digit',
                              minute: '2-digit',
                            })} - ${new Date(
                              mitarbeiterleistung.Arbeitsende
                            ).toLocaleTimeString('de-DE', {
                              hour: '2-digit',
                              minute: '2-digit',
                            })} Uhr | ${this.convertToMinutes(
                              mitarbeiterleistung.Pause
                            )} Min. Pause`,
                            ...mitarbeiterleistung,
                          })
                        ),
                    },
                  ]
                : []
          ),
        })),
      };

      const accumulateTimes = (levelData, itemData) => {
        levelData.Arbeitszeit = this.addTimes(
          levelData.Arbeitszeit,
          itemData.Arbeitszeit
        );
        levelData.Arbeitszeit_Nachtschicht = this.addTimes(
          levelData.Arbeitszeit_Nachtschicht,
          itemData.Arbeitszeit_Nachtschicht
        );
        levelData.Arbeitszeit_Wochenende = this.addTimes(
          levelData.Arbeitszeit_Wochenende,
          itemData.Arbeitszeit_Wochenende
        );
      };

      arbeitszeitData.Auftraege.forEach(order => {
        order.Pruefpositionen.forEach(pruefposition => {
          pruefposition.Mitarbeiterleistungen.forEach(mitarbeiterleistung => {
            accumulateTimes(pruefposition, mitarbeiterleistung);
          });
          accumulateTimes(order, pruefposition);
        });
        accumulateTimes(arbeitszeitData.gesamt, order);
      });

      return arbeitszeitData;
    },
  },
  watch: {
    'berichtData.Mitarbeiterbericht_Art': function (newValue, oldValue) {
      if (newValue === 'Abschlussbericht') {
        this.berichtData.Startdatum = new Date(
          this.orderData.Auftragskonditionen.Startdatum
        );
        this.berichtData.Enddatum = new Date();
        this.getMitarbeiterData(
          this.berichtData.Startdatum,
          this.berichtData.Enddatum
        );
      }
    },
    'berichtData.Mitarbeiterberichtnummer': async function (
      newValue,
      oldValue
    ) {
      if (newValue !== oldValue) {
        const qs = require('qs');
        const query = qs.stringify(
          {
            filters: {
              Mitarbeiterberichtnummer: {
                $eq: this.berichtData.Mitarbeiterberichtnummer,
              },
            },
            fields: ['id', 'Mitarbeiterberichtnummer'],
          },
          {
            encodeValuesOnly: true,
          }
        );

        await this.$http
          .get(`/mitarbeiterberichte?${query}`)
          .then(response => {
            if (response.data.data.length > 0) {
              this.reportIdNotUnique = true;
            } else {
              this.reportIdNotUnique = false;
            }
          })
          .catch(err => console.log(err.response.data.error.message));
      }
    },
  },
  mounted() {
    this.initTrHeight();
  },
  beforeRouteEnter(to, from, next) {
    // Initialize Breadcrumbs
    to.meta.breadcrumb[1] = {
      text: `Mitarbeiterbericht`,
      active: true,
    };
    next();
  },
  async created() {
    window.addEventListener('resize', this.initTrHeight);
    await this.getMitarbeiterData(
      new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      new Date()
    );

    // Change Breadcrumbs
    this.$route.meta.breadcrumb[1] = {
      text: `${this.mitarbeiterData.Vorname} ${this.mitarbeiterData.Nachname}`,
      to: { path: `/mitarbeiter/ansicht/${this.$route.params.id}` },
    };
    this.$router.replace({ query: { temp: Date.now() } });
    this.$router.replace({ query: { temp: undefined } });

    // Set initial Mitarbeiterberichtnummer
    this.berichtData.Mitarbeiterberichtnummer = `${
      this.mitarbeiterData.Mitarbeiternummer
    }-${this.mitarbeiterData.Mitarbeiterbericht.length + 1}`;

    this.initTrHeight();
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight);
  },
  methods: {
    addTimes(time1, time2) {
      const timeToMinutes = time => {
        if (!time) {
          return 0;
        }
        const [hours, minutes] = time.split(':').map(Number);
        return hours * 60 + minutes;
      };

      const minutesToTime = minutes => {
        const hours = String(Math.floor(minutes / 60)).padStart(2, '0');
        const remainingMinutes = String(minutes % 60).padStart(2, '0');
        return `${hours}:${remainingMinutes}:00`;
      };

      const totalMinutes = timeToMinutes(time1) + timeToMinutes(time2);
      return totalMinutes ? minutesToTime(totalMinutes) : null;
    },
    convertToIndustrialHours(timeString) {
      const timeParts = timeString.split(':');
      const hours = +timeParts[0];
      const minutes = Math.round((+timeParts[1] / 60) * 100);
      return `${hours},${minutes}`;
    },
    convertToMinutes(time) {
      const splitTime = time.split(':');
      const hours = parseInt(splitTime[0]);
      const minutes = parseInt(splitTime[1]);
      const totalMinutes = hours * 60 + minutes;
      return totalMinutes;
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        if (this.noOrders) {
          this.trSetHeight(29);
        } else {
          this.trSetHeight(this.$refs.form.scrollHeight);
        }
      });
    },
    async getMitarbeiterData(startDate, endDate) {
      if (!endDate) {
        endDate = startDate;
      }
      if (!startDate) {
        startDate = endDate;
      }
      if (typeof startDate === 'string') {
        startDate = new Date(startDate);
      }
      if (typeof endDate === 'string') {
        endDate = new Date(endDate);
      }
      let modifiedEndDate = new Date(endDate);
      endDate = new Date(
        modifiedEndDate.setDate(modifiedEndDate.getDate() + 1)
      );

      // Fetch Order Data
      const qs = require('qs');
      const query = qs.stringify(
        {
          fields: [
            'id',
            'Vorname',
            'Nachname',
            'Mitarbeiternummer',
            'Anstellungsart',
            'Stundensatz',
          ],
          populate: {
            Auftraege: {
              fields: ['Auftragsnummer'],
              filters: {
                Pruefpositionen: {
                  Mitarbeiterleistungen: {
                    $and: [
                      {
                        Arbeitsbeginn: {
                          $gte: startDate,
                        },
                      },
                      {
                        Arbeitsbeginn: {
                          $lte: endDate,
                        },
                      },
                    ],
                  },
                },
              },
              populate: {
                Pruefpositionen: {
                  fields: ['Bezeichnung'],
                  filters: {
                    Mitarbeiterleistungen: {
                      $and: [
                        {
                          Arbeitsbeginn: {
                            $gte: startDate,
                          },
                        },
                        {
                          Arbeitsbeginn: {
                            $lte: endDate,
                          },
                        },
                        {
                          Mitarbeiter: {
                            id: {
                              $eq: this.mitarbeiterID,
                            },
                          },
                        },
                      ],
                    },
                  },
                  populate: {
                    Mitarbeiterleistungen: {
                      fields: [
                        'Arbeitsbeginn',
                        'Pause',
                        'Arbeitsende',
                        'Arbeitszeit',
                        'Arbeitszeit_Nachtschicht',
                        'Arbeitszeit_Wochenende',
                        'Arbeitszeit_Samstag',
                      ],
                      filters: {
                        $and: [
                          {
                            Arbeitsbeginn: {
                              $gte: startDate,
                            },
                          },
                          {
                            Arbeitsbeginn: {
                              $lte: endDate,
                            },
                          },
                          {
                            Mitarbeiter: {
                              id: {
                                $eq: this.mitarbeiterID,
                              },
                            },
                          },
                        ],
                      },
                      populate: {
                        Mitarbeiter: {
                          fields: ['id'],
                        },
                      },
                    },
                  },
                },
              },
            },
            User: {
              fields: ['id'],
              populate: {
                avatar: {
                  fields: ['url', 'formats'],
                },
              },
            },
            Mitarbeiterbericht: {
              fields: ['id'],
            },
          },
        },
        {
          encodeValuesOnly: true,
        }
      );

      await this.$http
        .get(`/mitarbeiters/${this.mitarbeiterID}?${query}`)
        .then(response => {
          this.mitarbeiterData = response.data.data;
          this.initTrHeight();
        })
        .catch(err => console.log(err.response.data.error.message));
    },
    async saveBericht() {
      this.$refs.registerReportForm.validate().then(async isValid => {
        if (!isValid || this.reportIdNotUnique) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Eingabe nicht korrekt!`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: `Die Eingabe ist nicht korrekt. Bitte überprüfen Sie alle Felder und probieren Sie es noch einmal.`,
            },
          });
          return;
        }

        const data = { ...this.berichtData };

        // If Mitarbeiterbericht_Art === 'Einzelner_Auftrag' => Remove all other Auftrage that don't match selectedOrder and remove "gesamt"
        if (this.berichtData.Mitarbeiterbericht_Art === 'Einzelner_Auftrag') {
          this.berichtDataGesamt.Auftraege =
            this.berichtDataGesamt.Auftraege.filter(
              order => order.Auftragsnummer === this.selectedOrder
            );
          this.berichtDataGesamt.gesamt = null;
        }
        data.Mitarbeiterleistungen = JSON.stringify(this.berichtDataGesamt);
        data.Mitarbeiter = { id: this.mitarbeiterID };

        await this.$http
          .post(`/mitarbeiterberichte`, { data })
          .then(response => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Bericht angelegt`,
                icon: 'SaveIcon',
                variant: 'success',
                text: `Der Bericht #${this.berichtData.Mitarbeiterberichtnummer} wurde erfolgreich gespeichert.`,
              },
            });

            this.$router.push({
              name: 'mitarbeiterbericht-vorschau',
              params: { id: response.data.data.id },
            });
          })
          .catch(err => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Anlegen des Berichts fehlgeschlagen!`,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: `Der Bericht konnte nicht gespeichert werden. Bitte überprüfen Sie die Eingabe und probieren Sie es noch einmal.`,
              },
            });
            console.log(err.response.data.error.message);
          });
      });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.tagesbericht-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import '@/assets/scss/tagesberichte.scss';
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
